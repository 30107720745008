<template>
  <b-row>
    <b-col cols="12" md="6" lg="5">
      <!-- Account profile image card-->
      <b-col cols="12">
        <profile-user-image-form></profile-user-image-form>
      </b-col>
      <b-col cols="12">
        <!-- Account password change card-->
        <div class="card mb-4">
          <div class="card-header">Cambiar contraseña</div>
          <div class="card-body">
            <profile-change-password-form></profile-change-password-form>
          </div>
        </div>
      </b-col>
    </b-col>
    <b-col cols="12" md="6" lg="7">
      <!-- Account details card-->
      <profile-account-form></profile-account-form>
    </b-col>
  </b-row>
</template>

<script>
import ProfileAccountForm from '@/components/Profile/ProfileAccountForm'
import ProfileChangePasswordForm from '@/components/Profile/ProfileChangePasswordForm'
import ProfileUserImageForm from '@/components/Profile/ProfileUserImageForm'

export default {
  name: 'Profile',

  components: {
    ProfileAccountForm,
    ProfileChangePasswordForm,
    ProfileUserImageForm
  },

  created () {
    this.$store.dispatch('userModule/getAuthUser')
  }
}
</script>

<style lang="scss" scoped>
.my-profile-card {
  @media screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }
}
</style>
