<template>
  <div class="card mb-4">
    <div class="card-header">Foto de perfil</div>
    <div class="card-body">
      <FormulateForm
        #default="{ isLoading }"
        @submit="submit"
      >
        <b-row>
          <!-- Foto de perfil -->
          <b-col cols="12">
            <FormulateInput
              type="image"
              name="profile_image"
              label="Selecciona una imagen para tu perfil"
              help="Carga un archivo en formato png o jpg con un tamaño máximo de 4 Mb."
              validation="mime:image/jpeg,image/png"
              v-model="formValues.file"
            />
          </b-col>
          <b-col cols="12">
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :label="isLoading ? 'Guardando imagen...' : 'Guardar imagen de perfil'"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import '../../../node_modules/@braid/vue-formulate/themes/snow/snow.scss'

import { mapActions, mapGetters } from 'vuex'

const formValues = () => ({
  file: null,
  processed: false
})

export default {
  name: 'ProfileUserImage',
  created () {
    this.formValues.file = this.getProfileImage()
  },
  data () {
    return {
      // Archivo seleccionado
      file: null,
      formValues: formValues(),
      publicPath: process.env.BASE_URL,
      values: [
        {
          url: this.publicPath + 'profile-1.png', // url is required
          name: 'Profile image' // name is optional
        }
      ]
    }
  },
  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser' })
  },
  mutations: {
    setAuthUser (state, authUser) {
      state.authUser = authUser
    }
  },
  methods: {
    ...mapActions('userModule', ['getAuthUser']),
    async submit (data) {
      this.processingMessage = 'Procesando imagen, por favor espere...'
      this.formValues.processed = false
      const getval = await this.$store.dispatch('userModule/uploadProfileImg', {
        id: this.$store.getters['userModule/getAuthUser'].id,
        file: this.formValues.file.files[0].file
      })
      //
      // Se notifica al usuario
      this.$notify(getval, 'Guardando imagen de perfil')
      //
      // Termina si ha encontrado un error de validación en el archivo
      if (getval.error) {
        //
        // Asigna los errores para mostrarlos al usuario
        this.errors = getval.data

        //
        // Muestra el detalle de los errores de validación
        this.$bvModal.show('modal-errors')

        this.formValues.file = null

        return
      }

      await this.getAuthUser()

      this.formValues.processed = true
    },
    getProfileImage () {
      // Todo: refactorizar reutilizando el método
      const image = this.authUser.profile_url
      console.log(image)
      return (image != null) ? image : this.publicPath + 'profile-1.png'
    },
    resetForm () {
      try {
        this.formValues = formValues()
      } catch (_) {}
    }
  }
}
</script>
